/*
 * Copyright (C) 2018 - 2024 Entgra (Pvt) Ltd, Inc - All Rights Reserved.
 *
 * Unauthorised copying/redistribution of this file, via any medium is strictly prohibited.
 *
 * Licensed under the Entgra Commercial License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *       https://entgra.io/licenses/entgra-commercial/1.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.customFormItem {
    border: 1px solid #30658d;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.customFormItem .ant-select-selector {
    border: 1px solid #30658d;
    border-radius: 4px;
}

.customFormItem .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #30658d;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.customFormItem .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #30658d;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.customFormItem .ant-select-selection-item {
    border: none;
}

.customFormItem .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ffff;
    box-shadow: 0 0 0 2px rgb(255, 255, 255);
    border-right-width: 1px;
    outline: 0;
}
