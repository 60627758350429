/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.content {
    padding: 10px 10px 0 10px;
}

.iFrameContainer {
    padding: 10px;
    margin-bottom: 5px;
}

.controllerDiv {
    padding-bottom: 30px;
    padding-left: 10px;
}

.col {
    margin-right: 30px;
}

.pageHeader {
    border-radius: 10px;
    padding-top: 16px;
    padding-bottom: 0;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.positioning {
    margin-top: -17px;
    padding-bottom: 17px;
}

.commonStyle {
    width: 250px;
    text-align: right;
}

.meterTypeColumn {
    width: 400px;
    text-align: right;
    font-size: 15px;
    margin: 0;
    padding-top: 20px;
    color: #666f77;
}

.sidePadding {
    padding: 5px 30px 0px 30px;
}

.title {
    margin-left: 30px;
    color: rgba(0, 0, 0, 0.70) !important;
}

.cardBody {
    padding: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    padding: 30px 0 30px 0;
}

.column {
    flex: 1;
    margin: 0 10px;
    text-align: center;
}

.column h6 {
    font-size: 15px;
    margin: 0;
    padding-top: 20px;
    color: #666f77;
}

.inputField {
    width: 200px;
}

.selectValuesPicker {
    align-items: center;
    max-width: 500px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 1px 1px;
    color: #7b8087;
    font-size: 14px;
    cursor: pointer;
    margin: 3px 5px 0px 5px;
}

.labelText {
    font-weight: bold;
}

.dateRange {
    margin-right: 10px;
}

.refreshRatePicker {
    align-items: center;
    width: 120px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 1px 1px;
    color: #7b8087;
    font-size: 14px;
    cursor: pointer;
    margin-left: 30px;
}

.refreshRateSelect {
    border: none;
    color: #7b8087;
    font-size: 14px;
}

.refreshIcon {
    padding-left: 5px;
}
