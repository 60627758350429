
.viewAllButton {
    margin-top: 5px;
    text-align: center;
}

.formattedItems :global .ant-descriptions-view {
    overflow-x: auto;
}

.formattedTableItems :global .ant-descriptions-view {
    overflow-x: auto;
}

.formattedTableItems :global .ant-descriptions-item-label {
    border-bottom: 1px solid #f0f0f0;
}

.formattedTableItems :global .ant-descriptions-item-content {
    overflow-x: auto;
    background-color: #ffffff;
    border-bottom: 1px solid #f0f0f0;
}

.table {
    padding: 24px 70px;
}

.tableContainer {
    padding: 10px 10px;
    border: 1px solid rgba(187, 187, 187, 0.2);
    border-radius: 7px;
    margin-top: -10px;
}

.searchCollapse {
    background: #fafafa;
    border-radius: 10px;
    border: 0px;
    overflow: hidden;
    margin-bottom: 20px;
}

.customEmpty{
    margin: 0;
}

.operationDetailsModal :global .ant-modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.pageHeader {
    border-radius: 10px;
    padding-top: 16px;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.icon{
    color: rgba(0, 0, 0, 0.65);
    margin-right: 6px;
    padding-left: 46px;
}

.description {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: -3px;
    margin-top: -15px;
}

.positioning {
    margin-top: -17px;
    padding-bottom: 17px;
}

.responseRow {
    background-color: #fffbe6;
    border: #ffe58f;
    padding: 8px 15px;
    line-height: 1.5715;
}

.flexContainer {
    display: flex;
}

.timestamp,
.response {
    flex: 1;
}

