/* styles.module.css */

.description {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: -3px;
    margin-top: -15px;
}

.pageHeader {
    border-radius: 10px;
    padding-bottom: 10px;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.icon{
    color: rgba(0, 0, 0, 0.65);
    margin-right: 6px;
    padding-left: 46px;
}

.table {
    padding: 24px 70px;
}

.searchCollapse {
    background: #fafafa;
    border-radius: 10px;
    border: 0px;
    overflow: hidden;
    margin-bottom: 10px;
}

.positioning {
    margin-top: -17px;
    padding-bottom: 17px;
}

.commonStyle {
    width: 250px;
    text-align: right;
}

.sidePadding {
    padding: 5px 30px 10px 30px;
}

.title {
    margin-left: 30px;
    color: rgba(0, 0, 0, 0.70) !important;
}

.cardBody {
    padding: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    padding: 30px 0 30px 0;
}

.column {
    flex: 1;
    margin: 0 10px;
    text-align: center;
}

.column h6 {
    font-size: 15px;
    margin: 0;
    padding-top: 20px;
    color: #666f77;
}

.inputField {
    width: 200px;
}

.col {
    margin-right: 30px;
}

.iframeContainer {
    padding: 10px;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
}
