/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.App {
  padding: 20px;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

.ant-input-affix-wrapper .ant-input{
    min-height: 0;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
    font-weight: 300;
}

img.twemoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.ant-menu-dark .ant-menu-item-selected {
    color: black !important;
}

#rc-tabs-0-panel-5 .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection-item {
    border: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ffff;
    box-shadow: 0 0 0 2px rgb(255, 255, 255);
    border-right-width: 1px;
    outline: 0;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    padding-top: 0px;
    padding-bottom: 0px;
}

.ant-table-thead > tr > th, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 7px 16px;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    width: 220px;
}

.ant-layout-footer {
    padding: 5px 50px;
}

.ant-page-header.has-breadcrumb {
    padding-top: 8px;
    padding-bottom: 8px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 16px;
}

.ant-layout-header .ant-menu {
    line-height: 56px;
}

.ant-layout-header {
    padding: 0;
    height: 55px;
    box-shadow: 0 2px 8px #f0f1f2;
    border-radius: 0px 0px 10px 10px;
    margin-top: 0px;
    margin-bottom: -16px;
}

.ant-btn {
    height: 36px;
    margin-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ant-modal-content {
    margin-right: -350px;
    margin-left: -250px;
}

.ant-notification {
    z-index: 1000;
    font-family: 'Poppins', monospace;
}

.ant-modal-body {
    margin-top: 30px;
}

.ant-page-header {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 16px !important;
}

.ant-btn-primary {
    color: #fff;
    border-color: #30658d;
    background: #30658d;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
}

.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    user-select: none;
    touch-action: manipulation;
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 10px;
}

td.ant-table-cell {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

th.ant-table-cell {
    padding-bottom: 11px !important;
    padding-top: 11px !important;
}
